<template>
	<div class="loader-content">
		<div :class="{ 'loader-inline': inline, 'loader-spinner': spinner }"></div>
	</div>
</template>

<script>
export default {
	props: {
		inline: { type: Boolean },
		spinner: { type: Boolean },
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;
.loader-content {
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader-inline,
.loader-inline:before,
.loader-inline:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	animation-fill-mode: both;
	animation: bblFadInOut 1.8s infinite ease-in-out;
	background-color: var(--color, map($colors, 'main'));
}
.loader-inline {
	color: #fff;
	font-size: 7px;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;
}
.loader-inline:before,
.loader-inline:after {
	content: '';
	position: absolute;
	top: 0;
}
.loader-inline:before {
	left: -3.5em;
	animation-delay: -0.32s;
}
.loader-inline:after {
	left: 3.5em;
}

@keyframes bblFadInOut {
	0%,
	80%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

.loader-spinner {
	position: relative;
	width: 100px;
	height: 100px;
}
.loader-spinner:before {
	content: '';
	position: absolute;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	top: 50%;
	left: 0;
	transform: translate(-5px, -50%);
	background: linear-gradient(to right, #ff0057 50%, #fabc2a 50%) no-repeat;
	background-size: 200% auto;
	background-position: 100% 0;
	animation: colorBallMoveX 1.5s linear infinite alternate;
}
.loader-spinner:after {
	content: '';
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	width: 2px;
	height: 100%;
	background: #ff0057;
}
@keyframes colorBallMoveX {
	0% {
		background-position: 0% 0;
		transform: translate(-15px, -50%);
	}
	15%,
	25% {
		background-position: 0% 0;
		transform: translate(0px, -50%);
	}
	75%,
	85% {
		background-position: 100% 0;
		transform: translate(50px, -50%);
	}
	100% {
		background-position: 100% 0;
		transform: translate(65px, -50%);
	}
}
</style>
