import axios from "@/axios";

export default {
    namespaced: true,

    actions: {
        create(_, { productId, comment, userName, email }) {
            return axios.post('/products/comment', { productId, comment, userName, email });
        },
    },
}