export const ABOUT_STORE = 'ABOUT_STORE';
export const VALUES_STORE = 'VALUES_STORE';
export const PRODUCTS_STORE = 'PRODUCTS_STORE';
export const ALBUMS_STORE = 'ALBUMS_STORE';
export const CATEGORIES_STORE = 'CATEGORIES_STORE';
export const CONTACTS_STORE = 'CONTACTS_STORE';
export const NEWS_STORE = 'NEWS_STORE';
export const SLIDERS_STORE = 'SLIDERS_STORE';
export const FILTERS_STORE = 'FILTERS_STORE';
export const PROJECTS_STORE = 'PROJECTS_STORE';
export const OFFERS_STORE = 'OFFERS_STORE';
export const CASH_STORE = 'CASH_STORE';
