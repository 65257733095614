import { render, staticRenderFns } from "./AddOtherProductDialog.vue?vue&type=template&id=010e7392&scoped=true"
import script from "./AddOtherProductDialog.vue?vue&type=script&lang=js"
export * from "./AddOtherProductDialog.vue?vue&type=script&lang=js"
import style0 from "./AddOtherProductDialog.vue?vue&type=style&index=0&id=010e7392&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010e7392",
  null
  
)

export default component.exports