<template>
	<footer
		class="footer"
		v-if="!['otp'].includes($route.name)">
		<div class="container">
			<div class="page-p">
				<div class="footer-top">
					<div>
						<div class="footer-top__content">
							ننتظر تواصلك<span class="big">..</span><span class="small"><br /></span>ونوعدك
							بالزين!
						</div>

						<router-link
							:to="{ name: 'contact' }"
							class="button button--outlined"
							>تواصل معنا</router-link
						>
					</div>
					<div class="social">
						<div v-if="contacts.instagram">
							<a
								:href="contacts.instagram"
								target="_blank">
								<i
									class="fab fa-instagram"
									style="font-size: 1.1rem"></i>
							</a>
						</div>
						<div v-if="contacts.twitter">
							<a
								:href="contacts.twitter"
								target="_blank">
								<img
									src="@/assets/x-footer.png"
									alt="" />
							</a>
						</div>
						<div v-if="contacts.youtube">
							<a
								:href="contacts.youtube"
								target="_blank">
								<i class="fab fa-youtube"></i>
							</a>
						</div>
						<div v-if="contacts.email">
							<a
								:href="'mailto://' + contacts.email"
								target="_blank">
								<i class="fas fa-envelope"></i>
							</a>
						</div>
					</div>
				</div>
				<div class="footer-bottom">
					<div>
						<div class="footer-bottom__brand image">
							<img
								src="@/assets/Logo/white-logo.png"
								alt="" />
						</div>
						<div class="footer-bottom__description">
							من ديرتك ويفهم ذوقك..هذا سند كويتي اباً عن جد
							<br />
							يعرف شلون يصيد الجوّ ومُلمّ بكل هبة جديدة
							<br />
							لذلك لا تشيل هم ووكلّ سند كل مهام التسويق
						</div>
						<div class="footer-bottom__links">
							<div class="links">
								<div class="links-item">
									<router-link :to="{ name: 'home' }"> الرئيسية </router-link>
								</div>
								<div class="links-item">
									<router-link :to="{ name: 'all-products' }"> الأقسام </router-link>
								</div>
								<div class="links-item">
									<router-link :to="{ name: 'all-projects' }"> معرض الأعمال </router-link>
								</div>
							</div>
						</div>
						<div class="footer-bottom__address">
							العنوان
							<br />
							الكويت، مدينة الكويت
						</div>
					</div>

					<div class="rights">
						<div>{{ new Date().getFullYear() }}.All Rights Reserved</div>
						<!-- <a href="https://www.beetronix.com"> BEETRONIX </a> -->
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			loading: true,
		};
	},

	computed: {
		...mapState({
			contacts: (state) => state.contacts.contacts,
		}),
	},

	mounted() {
		this.$store.dispatch('contacts/fetchAll').finally(() => {
			this.loading = false;
		});
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;

.footer {
	background-color: map($colors, 'sec');
	background-color: #082caf;
	padding-top: 3rem;
	background-image: url('@/assets/footer.png');
	background-position: calc(100% + 3rem) 0;
	background-size: 10rem;
	@media (min-width: 1200px) {
		padding-top: 6rem;
	}
}
.footer-top {
	padding-bottom: 3rem;
	border-bottom: 1px solid #aaa;
	display: flex;
	justify-content: space-between;

	&__content {
		color: white;
		font-weight: 700;
		font-size: 1.3rem;
		line-height: 2.5rem;
		margin-bottom: 2rem;
		@media (min-width: 992px) {
			font-size: 2rem;
		}

		.big {
			display: none;
		}
		@media (min-width: 992px) {
			display: flex;
			align-items: center;
			.big {
				display: initial;
			}
			.small {
				display: none;
			}
		}
	}

	.social {
		display: flex;
		margin-top: 1rem;
		> div {
			margin-right: 0.7rem;
			width: 2.3rem;
			height: 2.3rem;
			border-radius: 50%;
			background-color: #a5b4eb;
			color: #072cb0;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (min-width: 992px) {
				width: 3rem;
				height: 3rem;
				* {
					font-size: 1.5rem !important;
				}
			}
		}
		img {
			transform: translateY(2px);
			width: 1.1rem;
			height: 1.1rem;
			object-fit: contain;
		}
	}
}
.footer-bottom {
	padding-top: 3rem;
	@media (min-width: 1200px) {
		> div {
			display: flex;
			justify-content: space-between;
		}
	}
	* {
		font-size: 0.85rem;
		color: white;
	}
	&__brand {
		width: 8rem;
		margin-bottom: 2rem;
	}
	&__description {
		margin-bottom: 2rem;
	}
	&__links {
		margin-bottom: 1.8rem;
		.links {
			display: flex;
			flex-wrap: wrap;
			&-item {
				width: 25%;
				@media (min-width: 1200px) {
					width: 50%;
					margin-bottom: 1rem;

					padding-inline-start: 3rem;
				}
			}
		}
	}
	&__address {
		margin-bottom: 1.5rem;
	}

	.rights {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0;
		* {
			font-size: 8px;
			@media (min-width: 992px) {
				font-size: 12px;
			}
		}
	}
}
</style>
