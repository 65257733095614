import axios from "@/axios";

export default {
    namespaced: true,

    state: {
        allRatings: [],
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/ratings/rating-comments').then(({ data }) => {
                const storeData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                commit('STORE_RATINGS', storeData);
                return storeData;
            })
        },
        create(_, { productId, stars }) {
            return axios.post('/ratings', { productId, stars });
        },
        update(_, { id, productId, stars }) {
            return axios.put(`/ratings/${id}`, { productId, stars });
        },
    },

    mutations: {
        STORE_RATINGS(state, ratings) {
            state.allRatings = ratings;
        }
    }
}