// import { abandonedTwoHours } from './emails';
import store from '@/store';

window.addEventListener('visibilitychange', () => {
	setTimeout(() => {
		if (document.visibilityState === 'hidden') {
			if (store.state.auth.self) {
				if (store.state.cart.cart?.products.length) {
					const firstTime = localStorage.getItem('sendEmail');

					if (!firstTime || !JSON.parse(firstTime)) {
						// abandonedTwoDays();
						// abandonedTwoHours();

						localStorage.setItem('sendEmail', true);
					}
				}
			}
		}
	}, 0);
});
