import axios from '@/axios';

export default {
	namespaced: true,
	actions: {
		feedback({ commit }, { name, email, phone, type, body }) {
			return axios
				.post('/feedback', { name, email, phone, type, body })
				.then((res) => {
					return res;
				})
				.catch((err) => {
					return err.response;
				});
		},
	},
};
