import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		sliders: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios
				.get('/sliders')
				.then((res) => {
					commit(types.SLIDERS_STORE, res.data);
					return res.data;
				})
				.catch(() => {});
		},
	},

	mutations: {
		[types.SLIDERS_STORE](state, sliders) {
			state.sliders = sliders;
		},
	},
};
