import axios from 'axios';

export const token =
	'8A4CBC98F4FB0E2B29C7F61906E8F80F56D558226284E582CC7BDBD321D5F8FB5A2D9A2A5BBBDE5B5B9AC594567DBE11';

export default axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: {
		common: {
			'X-ElasticEmail-ApiKey': token,
		},
	},
});
