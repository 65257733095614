import emailAxios from './axios';

import store from '@/store';

function getPreheader(str) {
	return str ? str + '&emsp;&zwnj;'.repeat(150) : '';
}

/* abandoned cart 1 */
export const abandonedTwoHours = () => {
	emailAxios.post('/emails', {
		Recipients: [
			{ Email: store.state.auth.self.email, Fields: { firstname: store.state.auth.self.fullName } },
		],
		Content: {
			TemplateName: 'abandoned_cart_2_hours_raw',
			Merge: { url: 'https://sanadagency.com/cart', year: new Date().getFullYear(), preheader: '' },
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: 'لا تفوتك منتجاتك المُختارة في السلة يا {firstname}!',
		},
		Options: {
			TimeOffset: 60 * 2, // 2 hours
		},
	});
};

/* after purchase 2 */
export const purchaseAfterWeek = () => {
	emailAxios.post('/emails', {
		Recipients: [{ Email: store.state.auth.self.email }],
		Content: {
			TemplateName: 'after_purchase_1_week_raw',
			Merge: {
				url: 'https://sanadagency.com',
				firstname: store.state.auth.self.fullName,
				year: new Date().getFullYear(),
				preheader: getPreheader('منتجات قد تكون الإضافة الكاملة لمشروعك 🛍️'),
			},
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: 'اكتشف الجديد!',
		},
		Options: {
			TimeOffset: 60 * 24 * 7, // 1 week
		},
	});
};

/* after purchase 3 */
export const purchaseAfterTwoWeek = () => {
	emailAxios.post('/emails', {
		Recipients: [{ Email: store.state.auth.self.email }],
		Content: {
			TemplateName: 'after_purchase_2_weeks_raw',
			Merge: {
				url: 'https://sanadagency.com',
				firstname: store.state.auth.self.fullName,
				year: new Date().getFullYear(),
				preheader: getPreheader('تسوق الآن واكسب أكثر 💰'),
			},
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: 'استفد من النقاط برصيدك!',
		},
		Options: {
			TimeOffset: 60 * 24 * 7 * 2, // 2 week
		},
	});
};

/* after purchase 3 */
export const purchaseAfterThreeWeek = () => {
	emailAxios.post('/emails', {
		Recipients: [{ Email: store.state.auth.self.email }],
		Content: {
			TemplateName: 'after_purchase_3_weeks_raw',
			Merge: {
				url: 'https://sanadagency.com',
				firstname: store.state.auth.self.fullName,
				year: new Date().getFullYear(),
				preheader: getPreheader('شاركنا رأيك 🌟'),
			},
			From: 'SANAD <noreply@sanadagency.com>',
			Subject: 'نتمنى تكون تجربتك معنا مميزة!',
		},
		Options: {
			TimeOffset: 60 * 24 * 7 * 3, // 3 week
		},
	});
};
