var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingProducts)?_c('div',{staticClass:"loader-container"},[_c('b-loader',{attrs:{"spinner":""}})],1):_c('div',{attrs:{"id":"home"}},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-content page-p"},[_c('div',{staticClass:"header-content__sanad"},[_vm._v("سنـــد")]),_vm._m(0),_c('router-link',{staticClass:"button button--main",attrs:{"to":{ name: 'all-products' }}},[_vm._v(" ابدأ رحلتك الآن "),_c('i',{staticClass:"fas fa-arrow-left-long",staticStyle:{"margin-right":"5px"}})])],1),_vm._m(1)])]),_c('section',{staticClass:"categories-section page-p"},[_c('div',{staticClass:"container"},[_vm._m(2),(_vm.loadingCategories)?_c('b-loader',{attrs:{"inline":""}}):_c('div',{staticClass:"categories-content"},_vm._l((_vm.categories),function(item){return _c('div',{key:item.id,staticClass:"row-padding"},[_c('div',{staticClass:"categories-item",class:{ selected: _vm.selectedCategory == item.id },on:{"click":function($event){return _vm.route(item)}}},[_c('div',{staticClass:"categories-item__image image"},[_c('img',{attrs:{"src":item.image,"alt":item.name}})]),_c('div',{staticClass:"categories-item__name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"categories-item__more"},[_vm._v(" المزيد "),_c('i',{staticClass:"fas fa-arrow-left-long",staticStyle:{"font-size":"0.6rem","margin-inline-start":"5px"}})])])])}),0)],1)]),_c('section',{staticClass:"products-section page-p"},[_c('div',{staticClass:"container"},[_vm._m(3),_c('router-link',{staticClass:"button button--sec",attrs:{"to":{ name: 'all-products' }}},[_vm._v(" كل المنتجات ")]),_c('div',{staticClass:"products-grid"},_vm._l(([
						..._vm.products.filter((e) => e.inHomePage),
						..._vm.products.filter((e) => !e.inHomePage),
					].slice(0, _vm.maxProductCount)),function(item){return _c('div',{key:item.id,staticClass:"row-padding"},[_c('product-card',{attrs:{"data":item}})],1)}),0),(_vm.products.length > _vm.maxProductCount)?_c('button',{staticClass:"button button--main",on:{"click":function($event){return _vm.changeProductCount(true)}}},[_vm._v(" حمل المزيد من المنتجات ")]):_vm._e()],1)]),(_vm.reviews.length)?_c('div',[_vm._m(4),_c('Hooper',{attrs:{"itemsToShow":_vm.$breakpoints.mdAndUp ? 3.5 : 1.25,"rtl":"","centerMode":true,"autoPlay":true,"playSpeed":5000,"infiniteScroll":true,"wheelControl":false}},[_vm._l((_vm.reviews),function(review,i){return _c('Slide',{key:i},[_c('img',{staticClass:"slide-image",attrs:{"src":require("@/assets/icons/right-quotation-mark.png")}}),(review.commentData)?_c('p',{staticClass:"user-comment"},[_vm._v(_vm._s(review.commentData.comment))]):_vm._e(),_c('div',{staticClass:"slide-product-name"},[_c('div',{staticClass:"dash"}),_c('span',[_vm._v(_vm._s(review.product.name))])]),_c('div',{staticClass:"user-ratting"},[_c('div',{staticClass:"user"},[_c('span',{staticClass:"user-icon"},[_c('i',{staticClass:"fa-regular fa-circle-user"})]),_c('span',{staticClass:"user-name"},[_vm._v(" "+_vm._s(review.user.fullName)+" ")])]),_c('BRating',{attrs:{"value":+review.stars,"disabled":""}})],1)])}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2),(false)?_c('div',{staticClass:"container",staticStyle:{"margin-bottom":"5rem"}},[_c('button',{staticClass:"button button--sec mb-4"},[_vm._v(" عرض كل التقيممات ")])]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-content__description"},[_vm._v(" سندك الحقيقي "),_c('br'),_vm._v(" في عالم التسويق ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-image"},[_c('div',{},[_c('img',{attrs:{"src":require("@/assets/Shape.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-title-container"},[_c('h2',{staticClass:"home-title"},[_vm._v(" شبيك لبيك"),_c('span',{staticClass:"big"},[_c('br')]),_c('span',{staticClass:"small"},[_vm._v("..")]),_vm._v("التسويق صار بين يديك ")]),_c('p',{staticClass:"mb-3"},[_vm._v(" بكل حب..خدماتنا التسويقية صارت بين يديك، جمعناها لك "),_c('br'),_vm._v("وبسطناها حتى ترتاح من وجع الراس! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-title-container"},[_c('h2',{staticClass:"home-title"},[_c('span',{staticClass:"big"},[_vm._v("سنـــــــــــــد"),_c('br'),_vm._v("يسند أعمالك")]),_c('span',{staticClass:"small"},[_vm._v("سنــــــد..يسند أعمالك")])]),_c('p',{staticClass:"mb-3"},[_vm._v(" نعم يسندها"),_c('br'),_vm._v(" لأن هدفنا من بدينا إبداعنا إننا نكون سند أعمالك، سند مشاريعك، سند رحلتك الريادية الرهيبة ! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container px-5"},[_c('h2',{staticClass:"customer-opinions-title"},[_vm._v("آراء العملاء")])])
}]

export { render, staticRenderFns }