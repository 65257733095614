import countriesArabic from './countries-arabic.json'

export const countries = countriesArabic.map((country, i) => ({
    id: i + 1,
    name: country,
    currency: 'USD'
}));

/* [
    { id: 1, name: 'الإمارات العربية المتحدة', currency: 'USD' },
    { id: 2, name: 'المملكة العربية السعودية', currency: 'USD' },
    { id: 3, name: 'البحرين', currency: 'USD' },
    { id: 4, name: 'الكويت', currency: 'USD' },
    { id: 5, name: 'اليمن', currency: 'USD' },
    { id: 6, name: 'قطر', currency: 'USD' },
    { id: 7, name: 'عُمان', currency: 'USD' },
    { id: 8, name: 'الأردن', currency: 'USD' },
    { id: 9, name: 'سوريا', currency: 'USD' },
    { id: 10, name: 'لبنان', currency: 'USD' },
    { id: 11, name: 'فلسطين', currency: 'USD' },
    { id: 12, name: 'العراق', currency: 'USD' },
    { id: 12, name: 'السودان', currency: 'USD' },
    { id: 14, name: 'مصر', currency: 'USD' },
    { id: 15, name: 'ليبيا', currency: 'USD' },
    { id: 16, name: 'تونس', currency: 'USD' },
    { id: 17, name: 'الجزائر', currency: 'USD' },
    { id: 18, name: 'المغرب', currency: 'USD' },
    { id: 19, name: 'موريتانيا', currency: 'USD' },
    { id: 20, name: 'الصومال', currency: 'USD' },
    { id: 21, name: 'جيبوتي', currency: 'USD' },
    { id: 22, name: 'جزر القمر', currency: 'USD' },
] */

export const sortingTypes = [
    { id: 0, name: 'الأحدث إلى الأقدم' },
    { id: 1, name: 'الأعلى تقييماً' },
    { id: 2, name: 'الأدنى تقييماً' },
]