import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		projects: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios
				.get('/custom-services', {
					params: {
						type: 2,
						typeName: 'projects',
					},
				})
				.then((res) => {
					commit(types.PROJECTS_STORE, res.data);
					return res.data;
				});
		},

		fetchAllOrGet({ commit, state }) {
			return state.projects?.length
				? state.projects
				: axios
						.get('/custom-services', {
							params: {
								type: 2,
								typeName: 'projects',
							},
						})
						.then((res) => {
							commit(types.PROJECTS_STORE, res.data);
							return res.data;
						});
		},
	},

	mutations: {
		[types.PROJECTS_STORE](state, projects) {
			state.projects = projects;
		},
	},

	getters: {
		getById: (state) => (id) => state.projects?.find((e) => e.id === id),
	},
};
