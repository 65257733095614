<template>
	<div class="rating">
		<div class="rating-star" :class="{ 'rating-star--error': error, 'no-hover': disabled }">
			<span
				v-for="star in maxStars"
				:key="star.stars"
				:class="{ active: star <= stars, border: border }"
				class="star"
				@click="rate(star)"
			>
				<i
					:class="[
						star <= stars ? 'fas fa-star' : 'far fa-star',
						{ 'fa-2x': border },
					]"
				></i>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	name: "BRating",
	props: {
		value: { type: Number, default: 0 },
		maxStars: { type: Number, default: 5 },
		hasCounter: { type: Boolean, default: false },
		border: { type: Boolean, default: false },
		error: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},
	data() {
		return {
			stars: 0,
		};
	},
	watch: {
		value: {
			handler(val) {
				this.stars = val;
			},
			immediate: true
		}
	},
	methods: {
		rate(star) {
			if (
				typeof star === "number" &&
				star <= this.maxStars &&
				star >= 0 &&
				!this.disabled
			) {
				this.stars = this.stars === star ? star - 1 : star;
				this.$emit('input', this.stars);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.rating {
	color: #b7b7b7;
	margin: .6rem 0;
	.rating-star {
		&--error {
			.star {
				color: red;
			}
		}
		&:not(.no-hover):hover {
			.star {
				color: #fabc2a;
			}
		}
		.border {
			border: 1px solid #adadad;
			padding: 0.5rem;
			border-radius: 0.5rem;
			margin-left: 0.5rem;
		}
		.star {
			display: inline-block;
			transition: all 0.2s ease-in-out;
			cursor: pointer;
			&:hover {
				~ .star:not(.active) {
					color: inherit;
				}
			}
			&.active {
				color: #fabc2a;
			}
		}

		&.no-hover .star {
			cursor: default;
		}
	}
	.info {
		margin-top: 15px;
		font-size: 40px;
		text-align: center;
		display: table;
		.divider {
			margin: 0 5px;
			font-size: 30px;
		}
		.score-max {
			font-size: 30px;
			vertical-align: sub;
		}
	}
}
</style>
