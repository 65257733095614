import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		about: {},
		values: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/aboutus').then((res) => {
				commit(types.ABOUT_STORE, res.data);
				return res.data;
			});
		},
		fetchValues({ commit }) {
			return axios.get('/company-values').then((res) => {
				commit(types.VALUES_STORE, res.data);
				return res.data;
			});
		},
	},

	mutations: {
		[types.ABOUT_STORE](state, about) {
			state.about = about;
		},
		[types.VALUES_STORE](state, values) {
			state.values = values;
		},
	},
};
