import axios from '@/axios'
import * as types from '../mutations'

export default {
    namespaced: true,

    state: {
        offers: {}
    },

    actions: {
        fetchAll({ commit }) {
            return axios.get('/offers').then((res) => {
                const storeData = res.data.filter(c => c.isActive)
                commit(types.OFFERS_STORE, storeData);
                return storeData;
            })
        }
    },

    mutations: {
        [types.OFFERS_STORE](state, offers) {
            state.offers = offers;
        }
    },

    getters: {
        getOfferById: state => id => state.offers.find(c => c.id === id)
    }
}