import axios from '@/axios';
import { purchaseAfterThreeWeek, purchaseAfterWeek, purchaseAfterTwoWeek } from '@/emailAxios/emails';

export default {
	namespaced: true,

	actions: {
		fetchAll() {
			return axios.get('/orders/self').then(({ data }) => {
				return data;
			});
		},
		placeOrder(
			_,
			{
				fullName,
				phone,
				email,
				address,
				cashBack,
				destination = '-',
				transCode = '-',
				paymentType,
				coupon,
			},
		) {
			return axios
				.post('/orders/place', {
					fullName,
					phone,
					email,
					address,
					cashBack,
					destination,
					transCode,
					paymentType,
					code: coupon,
				})
				.then(({ data }) => {
					localStorage.removeItem('sendEmail');
					purchaseAfterThreeWeek();
					purchaseAfterWeek();
					purchaseAfterTwoWeek();
					return data;
				});
		},

		initMyFatoorah() {
			return axios.post('/orders/myfatoorah/session').then(({ data }) => data);
		},
		startPay(_, { sessionId, PaymentMethodId = null, cashBack, coupon }) {
			return axios
				.post('/orders/myfatoorah/pay', { sessionId, PaymentMethodId, cashBack, discount: coupon })
				.then(({ data }) => data);
		},

		getPoints() {
			const response = {};
			return Promise.all([
				axios.get('/users/get-point').then(({ data }) => {
					response.points = Number(data.points) || 0;
				}),
				axios.get('/users/get-point-expire-date').then(({ data }) => {
					response.date = data.expierIn || null;
				}),
			]).then(() => response);
		},
		shareLink(_, { email } = {}) {
			let query = {};
			if (email) {
				query = { email };
			}
			return axios.get('/users/generate-link', { params: query }).then(({ data }) => data);
		},
	},
};
