import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		filters: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/filters/hierarchy').then((res) => {
				commit(types.FILTERS_STORE, res.data);
				return res.data;
			});
		},

		fetchAllOrGet({ commit, state }) {
			return state.filters?.length
				? state.filters
				: axios.get('/filters/hierarchy').then((res) => {
						commit(types.FILTERS_STORE, res.data);
						return res.data;
				  });
		},
	},

	mutations: {
		[types.FILTERS_STORE](state, filters) {
			state.filters = filters;
		},
	},

	getters: {
		getById: (state) => (id) => state.filters?.find((e) => e.id === id),
	},
};
