import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		cart: null,
	},

	actions: {
		addToCart({ commit, rootState }, { product, quantity, num = 1, ...props }) {
			return axios
				.post('/cart/' + product, { quantity, upSellingIds: Object.values(props), notes: '', num })
				.then(({ data }) => {
					commit('root', data);
					return data;
				});
		},
		fetchCart({ commit }, { force = false } = {}) {
			let query = {};
			if (force) {
				query = { cart_id: localStorage.getItem('cart-id') };
			}
			return axios.get('/cart', { params: query }).then(({ data }) => {
				localStorage.setItem('cart-id', data.id);
				commit('root', data);
				return data;
			});
		},

		deleteItem({ commit }, { product, itemId, editing }) {
			return axios
				.post('/cart/' + product, { _method: 'delete' }, { params: { itemId } })
				.then(({ data }) => {
					if (!editing) commit('root', data);
					return data;
				});
		},
		editItem({ commit }, { product, itemId, quantity }) {
			return axios
				.post('/cart/' + product, { _method: 'put' }, { params: { itemId, quantity } })
				.then(({ data }) => {
					commit('root', data);
					return data;
				});
		},

		checkCoupon(_, { code }) {
			return axios.get('coupons/check', { params: { code } }).then(({ data }) => data);
		},

		clear({ commit, dispatch }) {
			commit('clear');
			dispatch('fetchCart');
		},
	},

	mutations: {
		root(state, cart) {
			state.cart = cart;
		},
		clear(state) {
			state.cart = null;
		},
	},
};
