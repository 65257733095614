<template>
	<div
		v-if="loadingProducts"
		class="loader-container">
		<b-loader spinner></b-loader>
	</div>
	<div v-else id="home">
		<header class="header">
			<div class="container">
				<div class="header-content page-p">
					<div class="header-content__sanad">سنـــد</div>
					<div class="header-content__description">
						سندك الحقيقي
						<br />
						في عالم التسويق
					</div>
					<router-link
						:to="{ name: 'all-products' }"
						class="button button--main">
						ابدأ رحلتك الآن
						<i
							class="fas fa-arrow-left-long"
							style="margin-right: 5px"></i>
					</router-link>
				</div>
				<div class="header-image">
					<div class="">
						<img
							src="@/assets/Shape.png"
							alt="" />
					</div>
				</div>
			</div>
		</header>

		<section class="categories-section page-p">
			<div class="container">
				<div class="home-title-container">
					<h2 class="home-title">
						شبيك لبيك<span class="big"><br /></span><span class="small">..</span>التسويق صار بين
						يديك
					</h2>
					<p class="mb-3">
						بكل حب..خدماتنا التسويقية صارت بين يديك، جمعناها لك <br />وبسطناها حتى ترتاح من وجع
						الراس!
					</p>
				</div>

				<b-loader
					v-if="loadingCategories"
					inline>
				</b-loader>
				<div
					v-else
					class="categories-content">
					<div
						class="row-padding"
						v-for="item in categories"
						:key="item.id">
						<!-- :to="{ name: 'all-products', query: { id: item.id } }" -->
						<div
							@click="route(item)"
							:class="{ selected: selectedCategory == item.id }"
							class="categories-item">
							<div class="categories-item__image image">
								<img
									:src="item.image"
									:alt="item.name" />
							</div>
							<div class="categories-item__name">{{ item.name }}</div>
							<div class="categories-item__more">
								المزيد
								<i
									style="font-size: 0.6rem; margin-inline-start: 5px"
									class="fas fa-arrow-left-long"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="products-section page-p">
			<div class="container">
				<div class="home-title-container">
					<h2 class="home-title">
						<span class="big">سنـــــــــــــد<br />يسند أعمالك</span>
						<span class="small">سنــــــد..يسند أعمالك</span>
					</h2>
					<p class="mb-3">
						نعم يسندها<br />
						لأن هدفنا من بدينا إبداعنا إننا نكون سند أعمالك، سند مشاريعك، سند رحلتك الريادية
						الرهيبة !
					</p>
				</div>
				<router-link
					:to="{ name: 'all-products' }"
					class="button button--sec">
					كل المنتجات
				</router-link>

				<div class="products-grid">
					<div
						class="row-padding"
						v-for="item in [
							...products.filter((e) => e.inHomePage),
							...products.filter((e) => !e.inHomePage),
						].slice(0, maxProductCount)"
						:key="item.id">
						<product-card :data="item"></product-card>
					</div>
				</div>
				<button
					v-if="products.length > maxProductCount"
					class="button button--main"
					@click="changeProductCount(true)">
					حمل المزيد من المنتجات
				</button>
			</div>
		</section>
		<div v-if="reviews.length">
			<div class="container px-5">
				<h2 class="customer-opinions-title">آراء العملاء</h2>
			</div>
			<Hooper
				:itemsToShow="$breakpoints.mdAndUp ? 3.5 : 1.25"
				rtl
				:centerMode="true"
				:autoPlay="true"
				:playSpeed="5000"
				:infiniteScroll="true"
				:wheelControl="false"
			>
				<Slide v-for="(review, i) in reviews" :key="i">
					<img
						src="@/assets/icons/right-quotation-mark.png"
						class="slide-image"
					>
					<p v-if="review.commentData" class="user-comment">{{ review.commentData.comment }}</p>
					<div class="slide-product-name">
						<div class="dash"></div>
						<span>{{ review.product.name }}</span>
					</div>
					<div class="user-ratting">
						<div class="user">
							<span class="user-icon">
								<i class="fa-regular fa-circle-user"></i>
							</span>
							<span class="user-name">
								{{ review.user.fullName}}
							</span>
						</div>
						<BRating :value="+review.stars" disabled/>
					</div>
				</Slide>
				<hooper-navigation slot="hooper-addons"></hooper-navigation>
			</Hooper>
		
			<div v-if="false" class="container" style="margin-bottom: 5rem">
				<button class="button button--sec mb-4">
					عرض كل التقيممات
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';
import { Hooper, Navigation as HooperNavigation, Slide } from 'hooper'
import BRating from '@/components/BRating.vue'

import 'hooper/dist/hooper.css'
import { getRandomIndexesWithoutRepetition } from '@/helpers/helpers';


export default {
	props: {
		loadingCategories: { type: Boolean, default: false },
		loadingProducts: { type: Boolean, default: false },
	},

	components: { ProductCard, BRating, Hooper, HooperNavigation, Slide},

	data() {
		return {
			maxProductCount: 6,
			selectedCategory: null,
		};
	},

	computed: {
		...mapState({
			products: (state) => state.products.products,
			categories: (state) => state.categories.categories,
			allRatings: (state) => state.rating.allRatings,
		}),

		reviews() {
			const reviews = []
			const indexes = getRandomIndexesWithoutRepetition(this.allRatings, 4);
			indexes.forEach(index => { reviews.push(this.allRatings[index]); });
			return reviews;
		}
	},

	methods: {
		route(item) {
			if (!this.selectedCategory) {
				this.selectedCategory = item.id;
				setTimeout(() => {
					this.selectedCategory = null;
					this.$router.push({ name: 'all-products', query: { id: item.id } }, () => {});
				}, 500);
			}
		},
		changeProductCount(add) {
			if (add) {
				this.maxProductCount += 4;
			} else {
				if (window.innerWidth >= 992) {
					this.maxProductCount = 8;
				} else {
					this.maxProductCount = 6;
				}
			}
		},
	},

	mounted() {
		this.changeProductCount();

		// window.addEventListener('resize', () => {
		// 	this.changeProductCount();
		// });
	},

	metaInfo() {
		return {
			title: 'سند',
		};
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;

.mt-4 {
	margin-top: 2rem;
}

.home-title {
	font-weight: 700;
	font-size: 1.35rem;
	color: map($colors, 'sec');
	margin-bottom: 0.7rem;
	@media (min-width: 1200px) {
		font-size: 2rem;
		margin-bottom: 1.6rem;
	}
}

.home-title-container {
	.big {
		display: none;
	}
	@media (min-width: 992px) {
		display: flex;
		align-items: center;
		.big {
			display: initial;
		}
		.small {
			display: none;
		}
		.home-title {
			max-width: 19rem;
			min-width: 19rem;
			@media (min-width: 1200px) {
				max-width: 23rem;
				min-width: 23rem;
			}
		}
	}
}

.main-content {
	padding-bottom: 2rem;
}

.header {
	background-image: url('@/assets/header.png');
	background-position: calc(100% + 3rem) 0;
	background-size: 10rem;
	background-color: map($colors, 'sec');
	padding-top: 10rem;
	@media (min-width: 992px) {
		background-image: url('@/assets/header-big.png');
		background-size: auto 120%;
		padding-top: 14rem;
	}

	.container {
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			// align-items: flex-end;
		}
	}

	&-content {
		margin-bottom: 2rem;
		&__sanad {
			font-weight: 900;
			font-size: 6rem;
			line-height: 7rem;
			color: map($colors, 'alt');
			@media (min-width: 992px) {
				font-size: 7.5rem;
				margin-bottom: 1rem;
			}
			@media (min-width: 1200px) {
				font-size: 9.5rem;
				margin-bottom: 1rem;
			}
		}
		&__description {
			font-weight: 800;
			font-size: 2.2rem;
			line-height: 1.6em;
			color: white;
			margin-bottom: 2rem;
			@media (min-width: 992px) {
				font-size: 3rem;
			}
			@media (min-width: 1200px) {
				font-size: 3rem;
			}
			@media (min-width: 1500px) {
				font-size: 3.7rem;
				margin-bottom: 4rem;
			}
		}
	}

	&-image {
		align-self: flex-end;
		height: 300px;
		text-align: end;
		@media (min-width: 992px) {
			height: 50vh;
		}
		@media (min-width: 1200px) {
			height: 60vh;
		}
		@media (min-width: 1500px) {
			height: 70vh;
		}
		div {
			width: 100%;
			height: 100%;
			img {
				height: 100%;
			}
		}
	}
}

.categories-section {
	padding-top: 2.3rem;
	padding-bottom: 5rem;
	background-color: map($colors, 'background-2');
}

.categories-content {
	display: flex;
	flex-wrap: wrap;

	$dark: #dce5fd;
	.row-padding .categories-item {
		background-color: white;
	}
	.row-padding:first-child {
		.categories-item {
			background-color: $dark;
		}
	}
	@media (max-width: 992px) {
		.row-padding:last-child {
			.categories-item {
				background-color: $dark;
			}
		}
	}
	@media (min-width: 992px) {
		.row-padding:nth-child(3) {
			.categories-item {
				background-color: $dark;
			}
		}
	}

	.categories-item {
		display: block;
		border-radius: 12px;
		padding: 1.5rem;
		&__image {
			width: 4.4rem;
			height: 4.4rem;
			margin-bottom: 1.5rem;
		}
		&__name {
			font-weight: 600;
			margin-bottom: 0.6rem;
		}
		&__more {
			font-size: 0.9rem;
			color: map($colors, 'sec');
		}
		transition: 0.2s;
		&.selected,
		&:hover {
			cursor: pointer;
			background-image: url('@/assets/header.png');
			background-position: 0 0;
			background-size: 4rem;
			background-color: map($colors, 'sec') !important;
			color: white !important;
			* {
				color: white !important;
			}
			box-shadow: map($shadows, 0);
		}
	}
	.row-padding {
		width: 50%;
		padding: 0.5rem;
		@media (min-width: 992px) {
			width: 25%;
		}
	}
}

.products-section {
	padding-top: 2.3rem;
	padding-bottom: 2rem;

	background-image: url('@/assets/product.png');
	background-position: -3rem -1px;
	background-size: 10rem;
	background-repeat: repeat-y;
	.products-grid {
		margin: 2rem 0;
	}
}

.customer-opinions-title {
	font-size: 1.5rem;
	font-weight: 900;
	margin-bottom: 1rem;
}
</style>

<style lang="scss">
@use '@/style' as *;
#home {
	.hooper {
		height: 16rem;
		margin-bottom: 1rem;
		overflow: hidden;
		.hooper-list .hooper-track {
			display: flex;
			align-items: center;
		}
		.hooper-slide {
			padding: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			// border: 1px solid red;
			filter: drop-shadow(0 8px 10px #c2c2c275);
			width: 26rem;
			height: 14rem;
			border-radius: 1.4rem;
			background-color: white;
			color: black;
			transform: scale(0.96);
			p {
				margin-top: 0.5rem;
				font-size: 1rem;
				@media screen and (min-width: $sm) {
					font-size: 1.1rem;
				}
			}
			.dash {
				width: 1.5rem;
				height: 0.1rem;
				background-color: #b4b4b4;
				margin-left: 0.5rem;
			}
			.slide-image {
				width: 20px;
				@media screen and (min-width: $sm) {
					width: 35px;
				}
			}
			.user-comment {
				font-size: .9rem ;
			}
			.slide-product-name {
				display: flex;
				align-items: center;
				margin-top: auto;
				font-size: .8rem ;
			}
			.user-ratting {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: auto;
				.user {
					display: flex;
					align-items: center;
					.user-icon {
						color: map($colors, 'sec');
					}
					.user-name {
						font-size: 0.8rem;
						margin-right: 0.5rem;
					}
				}
			}
		}
		.is-current {
			background-color: map($colors, 'sec');
			margin-top: 0;
			color: white;
			height: 14rem;
			z-index: 3;
			transform: scale(1);
			.user-icon {
				.fa-circle-user {
					background-color: #cccccc;
					border-radius: 50%;
				}
			}
		}
		.is-prev {
			// transform: translateX(-4rem);
		}
		.is-next {
			// transform: translateX(4rem);
		}
	}
}
</style>