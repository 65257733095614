export default {
	install(Vue) {
		const $breakpoints = Vue.observable({
			width: window.innerWidth,
			height: window.innerHeight,

			xxl: window.innerWidth === 1400,
			xxlAndUp: window.innerWidth >= 1400,
			xxlAndDown: window.innerWidth <= 1400,

			xl: window.innerWidth === 1200,
			xlAndUp: window.innerWidth >= 1200,
			xlAndDown: window.innerWidth <= 1200,

			lg: window.innerWidth === 992,
			lgAndUp: window.innerWidth >= 992,
			lgAndDown: window.innerWidth <= 992,

			md: window.innerWidth === 768,
			mdAndUp: window.innerWidth >= 768,
			mdAndDown: window.innerWidth <= 768,

			sm: window.innerWidth === 576,
			smAndUp: window.innerWidth >= 576,
			smAndDown: window.innerWidth <= 576,
		});

		window.addEventListener('resize', () => {
			$breakpoints.width = window.innerWidth;
			$breakpoints.height = window.innerHeight;
			
			$breakpoints.xxl = window.innerWidth === 1400;
			$breakpoints.xxlAndUp = window.innerWidth >= 1400;
			$breakpoints.xxlAndDown = window.innerWidth <= 1400;

			$breakpoints.xl = window.innerWidth === 1200;
			$breakpoints.xlAndUp = window.innerWidth >= 1200;
			$breakpoints.xlAndDown = window.innerWidth <= 1200;

			$breakpoints.lg = window.innerWidth === 992;
			$breakpoints.lgAndUp = window.innerWidth >= 992;
			$breakpoints.lgAndDown = window.innerWidth <= 992;

			$breakpoints.md = window.innerWidth === 768;
			$breakpoints.mdAndUp = window.innerWidth >= 768;
			$breakpoints.mdAndDown = window.innerWidth <= 768;

			$breakpoints.sm = window.innerWidth === 576;
			$breakpoints.smAndUp = window.innerWidth >= 576;
			$breakpoints.smAndDown = window.innerWidth <= 576;
		});

		Vue.prototype.$breakpoints = $breakpoints;
	}
}