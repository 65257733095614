import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	status: {
		albums: [],
	},

	actions: {
		// fetch all albums
		fetchAll({ commit }) {
			return axios.get('/albums').then((res) => {
				commit(types.ALBUMS_STORE, res.data);
				return res.data;
			});
		},

		// return the albums. and if it's empty fetch albums
		fetchAllOrGet({ commit, state }) {
			return (
				state.albums ||
				axios.get('/albums').then((res) => {
					commit(types.ALBUMS_STORE, res.data);
					return res.data;
				})
			);
		},

		// fetch one albums by it's id
		fetchById({ commit }, { id }) {
			return axios.get(`albums/${id}`).then((res) => res.data);
		},

		// get one albums by it's id, and if it's not found fetch it
		fetchByStateOrId({ commit, state }, { id }) {
			let albums = null;
			if (state.albums) {
				albums = state.albums.find((e) => e.id === id);
			}
			return albums || axios.get(`/albums/${id}`).then((res) => res.data);
		},
	},

	mutations: {
		// fill the state with albums
		[types.ALBUMS_STORE](state, albums) {
			state.albums = albums;
		},
	},

	getters: {
		// return one albums by it's id
		getById: (state) => (id) => state.albums.find((e) => e.id === id),
	},
};
