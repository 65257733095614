import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		categories: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/categories').then((res) => {
				commit(types.CATEGORIES_STORE, res.data);
				return res.data;
			});
		},

		fetchAllOrGet({ commit, state }) {
			return state.categories?.length
				? state.categories
				: axios.get('/categories').then((res) => {
						commit(types.CATEGORIES_STORE, res.data);
						return res.data;
				  });
		},
	},

	mutations: {
		[types.CATEGORIES_STORE](state, categories) {
			state.categories = categories;
		},
	},

	getters: {
		getById: (state) => (id) => state.categories?.find((e) => e.id === id),
	},
};
