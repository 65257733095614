<template>
	<div
		class="dialog-layout"
		@click.self="$emit('close')"
		v-if="product">
		<div class="dialog-card">
			<div
				class="dialog-card__close"
				@click="$emit('close')">
				<i class="fas fa-xmark"></i>
			</div>

			<div class="dialog-card__title">{{ product.name }}</div>
			<div
				class="upsales"
				ref="upSelling">
				<h3>{{ product.upSellingTitle }}</h3>

				<template v-for="(item, i) in product.upSelling">
					<div
						class="upsales-item upsales-item--bool-with-radio"
						:class="{ required: unSelectedRequired.includes(item.id) }"
						:key="item.id + ' one'">
						<span
							class="label"
							:class="{ 'required-filed': item.isRequired }">
							{{ item.text }}
						</span>

						<!-- yes no -->
						<fieldset v-if="item.type === 0 || item.type === 4">
							<span>
								<input
									v-model="upSelling[i].value"
									:name="item.id + 'up selling'"
									type="radio"
									:id="item.id + 'yes'"
									:value="true" />
								<label :for="item.id + 'yes'">نعم</label>
							</span>
							<span>
								<input
									v-model="upSelling[i].value"
									@input="upSelling[i].value2 = null"
									:name="item.id + 'up selling'"
									type="radio"
									:id="item.id + 'no'"
									:value="false" />
								<label :for="item.id + 'no'">لا</label>
							</span>
						</fieldset>

						<!-- radio -->
						<fieldset v-if="item.type === 2 || item.type === 5">
							<template v-if="item.options.length < 6">
								<span v-if="item.type == 5">
									<input
										@click="upSelling[i].value2 ? null : (upSelling[i].value2 = 1)"
										v-model="upSelling[i].value"
										type="radio"
										:name="item.id + 'option'"
										:id="item.text"
										:value="-1" />
									<label :for="item.text">{{ item.text }}</label>
								</span>
								<span
									@click="upSelling[i].value2 = 1"
									v-for="option in item.options"
									:key="option.id + ' option'">
									<input
										v-model="upSelling[i].value"
										type="radio"
										:name="item.id + 'option'"
										:id="option.text"
										:value="option.id" />
									<label :for="option.text">{{ option.text }}</label>
								</span>
							</template>

							<template v-else>
								<select v-model="upSelling[i].value">
									<option :value="null">-</option>
									<option
										:value="option.id"
										v-for="option in item.options"
										:key="option.id + ' option'">
										{{ option.text }}
									</option>
								</select>
							</template>
						</fieldset>

						<!-- check box -->
						<fieldset v-if="item.type === 3">
							<span
								v-for="(option, j) in item.options"
								:key="option.id + ' option'">
								<input
									v-model="upSelling[i].options[j]"
									type="checkbox"
									:name="item.id + 'option'"
									:id="option.text" />
								<label :for="option.text">{{ option.text }}</label>
							</span>
						</fieldset>
					</div>

					<div
						class="upsales-item upsales-item--radio option"
						:key="item.id + ' two'"
						v-if="item.options.length && upSelling[i].value && item.type === 4">
						<span class="label">{{ item.optionTitle }}</span>
						<fieldset>
							<span
								v-for="option in item.options"
								:key="option.id + ' option'">
								<input
									v-model="upSelling[i].value2"
									type="radio"
									:name="item.id + 'option'"
									:id="option.text"
									:value="option.id" />
								<label :for="option.text">{{ option.text }}</label>
							</span>
						</fieldset>
					</div>

					<!-- v-if="isBulk" -->
					<div
						v-if="upSelling[i].value === -1 && item.type === 5"
						:key="item.id + ' three'"
						style="display: flex">
						<div class="quantity">
							<div
								class="add"
								@click="upSelling[i].value2++">
								+
							</div>
							<input
								:value="upSelling[i].value2"
								class="quantity-input"
								type="text" />
							<div
								class="sub"
								@click="upSelling[i].value2 > 1 && upSelling[i].value2--">
								-
							</div>
						</div>
					</div>
				</template>
			</div>

			<div class="product-cart-summary">
				<div
					class="product-cart-summary__items"
					v-if="calcUpSellingPrice.length">
					<div
						class="item"
						v-if="productPriceAfterOffer">
						<div>سعر الخدمة</div>
						<div>{{ productPriceAfterOffer.toFixed(3) }}</div>
					</div>
					<div
						class="item"
						v-for="item in calcUpSellingPrice"
						:key="item.id">
						<template v-if="item.price">
							<div>{{ item.isBulk ? 'العدد' : item.name }}</div>
							<div>{{ item.isBulk ? `${isBulk}x` : item.price.toFixed(3) }}</div>
						</template>
					</div>
				</div>

				<div class="product-cart-summary__total">
					<div class="product-total">
						{{
							(
								calcUpSellingPrice.reduce((a, c) => a + c.price, productPriceAfterOffer) * quantity
							).toFixed(3)
						}}
					</div>
					<div class="actions">
						<div
							class="quantity"
							v-if="product.isQuantitative && !loadingCart && !sent">
							<div
								class="add"
								@click="quantity++">
								+
							</div>
							<input
								:value="quantity"
								class="quantity-input"
								type="text" />
							<div
								class="sub"
								@click="quantity > 1 ? quantity-- : null">
								-
							</div>
						</div>
						<button
							class="button button--sec add-to-cart"
							:disabled="loadingAdding || loadingCart"
							v-if="itemId"
							@click="addToCart">
							<b-loader
								style="--color: #ff0057; padding: 5px"
								v-if="loadingAdding || loadingCart"
								inline></b-loader>
							<span v-else> تعديل الخدمة </span>
						</button>
						<button
							v-else
							class="button button--sec add-to-cart"
							:disabled="
								loadingAdding ||
								sent ||
								(!product.isQuantitative && productIsOnCart) ||
								loadingCart
							"
							@click="addToCart">
							<b-loader
								style="--color: #ff0057; padding: 5px"
								v-if="loadingAdding || loadingCart"
								inline></b-loader>
							<span v-else-if="sent"> تم الإضافة إلى السلة </span>
							<span v-else-if="!product.isQuantitative && productIsOnCart">
								الخدمة في السلة
							</span>
							<span v-else>
								<i class="fas fa-cart-shopping"></i>
								إضافة إلى السلة
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import sha256 from 'sha256';
import { getOfferPrice } from '@/helpers/helpers';

export default {
	props: {
		product: { type: Object, default: () => {} },
		itemId: { type: Number },
		oldQuantity: { type: Number },
		num: { type: Number },
	},

	data() {
		return {
			loadingCart: false,
			loadingAdding: false,

			quantity: 1,
			upSelling: [],
			unSelectedRequired: [],

			sent: false,
		};
	},

	computed: {
		isBulk() {
			return this.upSelling.find((e) => e.type === 5 && e.value === -1)?.value2;
		},
		productIsOnCart() {
			if (!this.cart) return false;

			return Boolean(this.cart.products.find((e) => e.product.id === this.product.id));
		},
		calcUpSelling() {
			const arr = [];

			this.product.upSelling.forEach((e, i) => {
				if (e.type === 0) {
					if (this.upSelling[i].value) {
						arr.push({
							id: e.id,
							options: [],
						});
					}
				}

				if (e.type === 4) {
					if (this.upSelling[i].value && this.upSelling[i].value2) {
						arr.push({
							id: e.id,
							options: [this.upSelling[i].value2],
						});
					}
				}

				if (e.type === 2) {
					if (this.upSelling[i].value) {
						arr.push({
							id: e.id,
							options: [this.upSelling[i].value],
						});
					}
				}

				if (e.type === 5) {
					if (this.upSelling[i].value) {
						arr.push({
							isBulk: this.upSelling[i].value === -1,
							id: e.id,
							options: this.upSelling[i].value !== -1 ? [this.upSelling[i].value] : [],
						});
					}
				}

				if (e.type === 3) {
					if (this.upSelling[i].options.reduce((a, c) => a || c, false)) {
						arr.push({
							id: e.id,
							options: e.options
								.filter((_, j) => this.upSelling[i].options[j])
								.map((e) => e.id),
						});
					}
				}
			});

			return arr;
		},
		calcUpSellingPrice() {
			return this.calcUpSelling.map((e) => {
				const upSelling = this.product.upSelling.find((u) => e.id === u.id);
				const price = this.getUpSellingPrice(upSelling, e);
				const offerPice = getOfferPrice(this.product.offers, price, true);
				return {
					isBulk: e.isBulk,
					id: upSelling.id,
					name: upSelling.cartTitle,
					price: offerPice || price,
				};
			});
		},

		productPriceAfterOffer() {
			if (!this.product.price) return 0;
			const offerPrice =  getOfferPrice(
				this.product.offers,
				this.product.price ? this.product.price : this.product.secondPrice
			)
			if (offerPrice && offerPrice > 0) return offerPrice;
			return this.product.price || this.product.secondPrice
		},

		...mapState({
			cart: (state) => state.cart.cart,
			user: (state) => state.auth.self,
		}),
	},

	methods: {
		checkForRequired() {
			this.unSelectedRequired = [];

			this.upSelling.forEach((e, i) => {
				if (e.required) {
					if (
						(e.type === 0 && e.value == null) ||
						(e.type === 2 && e.value == null) ||
						(e.type === 3 && !e.options.filter((f) => f).length) ||
						(e.type === 4 && (e.value == null || (e.value === true && e.value2 == null))) ||
						(e.type === 5 && e.value2 == null)
					) {
						this.unSelectedRequired.push(e.id);
					}
				}
			});

			return this.unSelectedRequired.length;
		},
		getUpSellingPrice(item, selected) {
			switch (item.type) {
				case 0:
					return item.price;
				case 4:
					return item.options.find((e) => e.id === selected.options[0]).price;
				case 2:
					return item.options.find((e) => e.id === selected.options[0]).price;
				case 3:
					return selected.options
						.map((e) => item.options.find((o) => o.id === e))
						.reduce((a, c) => a + c.price, 0);
				case 5:
					if (!selected.isBulk) return item.options.find((e) => e.id === selected.options[0]).price;
					else return this.productPriceAfterOffer * (this.isBulk - 1);
			}
		},
		addToCart() {
			if (this.checkForRequired()) {
				return;
			}

			// editing
			if (this.itemId) {
				this.loadingAdding = true;
				this.$store
					.dispatch('cart/deleteItem', {
						product: this.product.id,
						itemId: this.itemId,
						editing: true,
					})
					.then(() => {
						this.$store
							.dispatch('cart/addToCart', {
								product: this.product.id,
								quantity: this.product.isQuantitative
									? this.quantity
									: this.isBulk
									? this.isBulk
									: 1,
								num: this.num,
								...this.calcUpSelling,
							})
							.then(() => {
								this.sent = true;
								this.$eventBus.$emit('snackbar', {
									message: 'تم تعديل المنتج',
									isError: false,
								});

								const productPrice = (
									this.calcUpSellingPrice.reduce(
										(a, c) => a + c.price,
										this.productPriceAfterOffer,
									) * this.quantity
								).toFixed(3);

								if (this.user)
									window.fbq('track', 'AddToCart', {
										content_type: 'product',
										contents: [{ id: this.product.slug, quantity: this.quantity }],
										content_name: this.product.name,
										value: productPrice,
										currency: 'KWD',
										user_data: {
											em: sha256(this.user.email.trim().toLocaleLowerCase()),
											ph: sha256(this.user.phone),
											fn: sha256(this.user.fullName),
										},
									});
								else
									window.fbq('track', 'AddToCart', {
										content_type: 'product',
										contents: [{ id: this.product.slug, quantity: this.quantity }],
										content_name: this.product.name,
										value: productPrice,
										currency: 'KWD',
									});
							})
							.finally(() => {
								this.$emit('close');
								this.loadingAdding = false;
							});
					})
					.catch(() => {});
			}
			// adding
			else {
				this.loadingAdding = true;
				this.$store
					.dispatch('cart/addToCart', {
						product: this.product.id,
						quantity: this.product.isQuantitative ? this.quantity : this.isBulk ? this.isBulk : 1,
						...this.calcUpSelling,
					})
					.then(() => {
						this.sent = true;
						this.$eventBus.$emit(
							'show-other-product-dialog',
							this.product,
							(
								this.calcUpSellingPrice.reduce((a, c) => a + c.price, this.productPriceAfterOffer) *
								this.quantity
							).toFixed(3),
						);
						this.$eventBus.$emit('snackbar', {
							message: 'تم إضافة المنتج إلى السلة',
							isError: false,
						});
						this.$emit('close');

						const productPrice = (
							this.calcUpSellingPrice.reduce((a, c) => a + c.price, this.productPriceAfterOffer) *
							this.quantity
						).toFixed(3);

						if (this.user)
							window.fbq('track', 'AddToCart', {
								content_type: 'product',
								contents: [{ id: this.product.slug, quantity: this.quantity }],
								content_name: this.product.name,
								value: productPrice,
								currency: 'KWD',
								user_data: {
									em: sha256(this.user.email.trim().toLocaleLowerCase()),
									ph: sha256(this.user.phone),
									fn: sha256(this.user.fullName),
								},
							});
						else
							window.fbq('track', 'AddToCart', {
								content_type: 'product',
								contents: [{ id: this.product.slug, quantity: this.quantity }],
								content_name: this.product.name,
								value: productPrice,
								currency: 'KWD',
							});
					})
					.catch(() => {})
					.finally(() => {
						this.loadingAdding = false;
					});
			}
		},
	},

	created() {
		this.quantity = this.oldQuantity || 1;
		this.product.upSelling.forEach((e) => {
			this.upSelling.push({
				id: e.id,
				required: e.isRequired,
				type: e.type,
				value: null,
				value2: null,
				options: e.options.map(() => null),
			});
		});
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;
.upsales {
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: auto;
	h3 {
		font-size: 1.05rem;
		font-weight: 500;
		margin-bottom: 0rem;
	}
	&-item {
		&.required {
			position: relative;
			z-index: 1;
			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: -5px;
				left: -8px;
				right: -8px;
				bottom: -5px;
				background-color: #ff005730;
				border-radius: 5px;
			}
		}
		margin-bottom: 2rem;
		.label {
			margin-inline-end: 1.6rem;
		}
		fieldset {
			border: none;
			// display: flex;
			span {
				display: block;
				&:not(:last-child) {
					margin-block-end: 0.6rem;
				}
				label {
					margin-inline-start: 5px;
				}
			}
		}
		select {
			padding: 3px 1rem;
			border-radius: 5px;
			border: 1px solid #333;
		}
		&--radio {
			&.option {
				margin-top: -1rem;
				padding: 1rem;
				border-radius: 8px;
				background-color: #eeeef8;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					z-index: -1;
					background-color: #eeeef8;

					width: 2rem;
					height: 2rem;
					border-radius: 8px;
					transform: rotate(45deg);
					top: -0.65rem;
					right: 3rem;
				}
			}
		}
	}
}
.product-cart-summary {
	margin: 2rem auto 0;
	@media (min-width: 992px) {
		margin: 0;
		position: sticky;
		top: 120px;
	}

	&__items {
		max-height: 200px;
		overflow: auto;
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid #ddd;
		.item {
			display: flex;
			padding: 5px 0;
			> div:first-child {
				min-width: 8.5rem;
				max-width: 8.5rem;
			}
		}
	}
	&__total {
		.product-total {
			margin-bottom: 0.5rem;
			margin-inline-start: 8.5rem;
			font-size: 2.2rem;
			font-weight: 600;
			color: green;
			&::after {
				content: var(--active-currency);
				margin-inline-start: 7px;
				font-size: 0.6em;
				font-weight: 400;
			}
		}
		.actions {
			display: flex;
			.add-to-cart {
				flex: 1;
			}
		}
	}
}
</style>
