import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		cashback: 0.05,
	},

	actions: {
		getCashback({ commit }) {
			return axios.get('').then(({ data }) => {
				commit(types.CASH_STORE, data);
				return data;
			});
		},
	},

	mutations: {
		[types.CASH_STORE](state, cashback) {
			state.cashback = cashback;
		},
	},
};
