let activeCurrency = 'د.ك';
let activeCurrencyCode = 'KWD';

const changeCurrency = (currency) => {
    activeCurrencyCode = currency;
    localStorage.setItem('currency', JSON.stringify(currency));
    if (currency === 'KWD') {
        activeCurrency = 'د.ك';
        activeCurrencyCode = 'KWD';
    }
    else {
        activeCurrency = '$';
        activeCurrencyCode = 'USD';
    }
    document.documentElement.style.setProperty('--active-currency', `"${activeCurrency}"`);
}
changeCurrency(JSON.parse(localStorage.getItem('currency')) || 'KWD');

export { activeCurrency, activeCurrencyCode, changeCurrency }